@import '../Header/Header.module';
.footer {
  display: flex;
  flex-direction: column;
  font-family: 'Kulim Park', sans-serif;
  width: 100%;
  bottom: 0;
  background-color: #2d3236;
  color: white;
}

.footerContent  {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include adaptive_margin(1rem,0,1rem,2rem);

}

