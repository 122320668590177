@import '../Header/Header.module';
.cards{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @include adaptive_margin(1rem,2rem,1rem,2rem);
  padding: 15px;
  box-shadow: 7px 7px 10px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 15px;
  color: inherit;
  text-decoration: none;

}

.cards:hover {
  color: black;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cardsTitle{
  font-weight: bold;
}

.cardsDescription{
  margin: 15px;
}


.cardsFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: grey;
  font-size: 15px;
}


