
@mixin adaptive_margin($top:1rem,$right:1rem,$bottom:1rem,$left:1rem) {
  @media screen and (max-width: 700px) { // 0-700px
    margin: $top 0.5*$right $bottom 0.5*$left;
  }

  @media screen and (min-width: 700px) { // 700 - 1100 px
    margin: $top $right $bottom $left;
  }

  @media screen and (min-width: 1100px) { // > 1100 px
    margin: $top 1.2*$right $bottom 1.2*$left;
  }

}



.header {
  display: flex;
  flex-direction: column;


}

.headerContent  {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.title {
  @include adaptive_margin($left:2rem);
  font-weight: bold;
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  a{
    color: inherit;
    text-decoration: none;
  }

}

.login {
  @include adaptive_margin($right:2rem);
  font-size: 1.1rem;
  cursor: pointer;
}

.horizontalLine {
  border-bottom: grey 1px solid;
  @include adaptive_margin(0,2rem,1rem,2rem);
}

.an {
  margin: 1rem 0.7rem 1.1rem 1rem;
  width:  1.3rem;
  height: 1.3rem;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 0;
  .btn-close {
    margin: 0;
    position: absolute;
    right: 1.5rem;
  }
}
.modal-dialog {
  margin-top: 8rem;
}
.modal-title {
  font-weight: bold;

}

.loginForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 1rem 2rem 2rem 2rem;
  padding: 15px;
  box-shadow: 0 0 20px 5px rgba(0,0,0,0.2);
  border-radius: 10px;

}

.panel{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;



  .panelText {
    padding: 1rem 0 0.1rem 0;
    margin: 0 1rem;


  }
  .panelText:hover{
    cursor: pointer;
  }

}

form {
  margin-top: 1.5rem;
}