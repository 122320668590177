.comment{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.commentTitle{
  font-weight: bold;
  font-size: calc(1.325rem + .9vw);

}

.commentDivider{
    border-bottom: 1px solid #808080;
    margin: 1rem 0;
}
.commentAuthor{
  font-weight: bold;
}
.commentTime{
  color: rgb(153, 153, 153);
  margin-bottom: 0.7em;
}
