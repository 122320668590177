@import '../Header/Header.module';
.article{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @include adaptive_margin(0,2rem,1rem,2rem);
  flex-grow: 1;
}

.article {
  .spinner-border {
    width: 5em;
  }
  img {
    width: auto;
    max-width: 100%;
  }
  button {
    width: auto;
  }
}

.article {
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }
}

