@charset "UTF-8";

*{
  box-sizing: border-box;
}

html {
  font-size: 18px;
  min-height: 100%;
}

body{
  font-family: "Times New Roman", Times, serif;
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
}

@media screen and (min-width: 700px) { // ipad 700-1100px
  html {
    font-size: 20px;
  }
}

@media screen and (min-width: 1100px) { // desktop > 1100px
  html {
    font-size: 22px;
  }
}

.spinner-border {
  align-self: center;
  margin-top: 2em;
  height: 5em;
  width: 5em;
}